import { template as template_82a96176bd9c4b29ac6c757104e786ff } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_82a96176bd9c4b29ac6c757104e786ff(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
